import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import CustomButton from '../../Components/CustomButton';
import { useNavigate } from 'react-router';
import { Modal } from 'react-bootstrap';
import BASEURL from '../../Config/global';
import axios from 'axios';

const PaymentScreen = () => {
    const [couponDetail, setCouponDetail] = useState();
    const [loading, setLoading] = useState(false);
    const [couponPaymentError, setCouponPaymentError] = useState({ error: false, text: "" });
    const [paymentDecline, setPaymentDecline] = useState(false);
    const [couponError, setCouponError] = useState({ error: false, text: "" });
    const [succesModal, setSuccesModal] = useState(false);

    const navigate = useNavigate();
    const stripe = useStripe()
    const [couponData, setCouponData] = useState([]);

    const elements = useElements()

    useEffect(() => {
        const checkUser = JSON.parse(localStorage.getItem("user"));
        if (checkUser?.id) {
            setCouponDetail(checkUser?.coupon);
        }
        getCouponDataByApi(checkUser?.coupon?.id)
        return () => {
            sessionStorage.clear();
        };
    }, [])
    const getCouponDataByApi = async (id, status) => {
        setLoading(true);
        try {
            const response = await axios.get(`${BASEURL}/api/user/get_coupon?coupon_id=${id}`);
            if (!response.data.error) {
                setCouponData(response.data.data[0]);
                setCouponDetail(response.data.data[0].coupon_detail[0]);
                setLoading(false);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setCouponError({ error: true, text: "Promo invalid" });
            setLoading(false);
        }
    }
    async function signUpAPi() {
        setLoading(true);
        setCouponPaymentError({ error: false, text: "" });
        try {
            if (!stripe || !elements) {
                return;
            }
            const cardElement = elements.getElement(CardElement);
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (error) {
                setLoading(false);
                if (error.message.includes('Invalid API Key provided:')) {
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                } else {
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                    // setCouponPaymentError({ error: true, text: error.message });
                    console.error(error.message);
                    setPaymentDecline(true);
                    setTimeout(() => {
                        setPaymentDecline(false);
                    }, 2500);
                }
                // setCouponPaymentError({ error: true, text: error.message });
            } else {
                let parseData = JSON.parse(localStorage.getItem("user"));
                const userEmail = parseData.email;
                const { propagation_trial_period, subscription_user_propagate, coupon_detail } = couponData
                const planId = subscription_user_propagate ? couponData?.propagation_plan_id : couponData?.plan_id;
                // const trial_period = couponDetail?.trial_period;
                const formData = new FormData();
                formData.append('email', userEmail);
                formData.append('plan_id', planId);
                if (couponData.propagation_duration_type === 'trial_period' && Number(couponData.propagation_trial_period) > 0) {
                    formData.append('trial_period', couponData.propagation_trial_period);
                }
                else if (couponData.propagation_duration_type === 'term_in_days' && Number(couponData.propagation_term_in_days) > 0) {
                    formData.append('trial_period', couponData.propagation_term_in_days);
                }
                formData.append('platform', "web");
                formData.append('paymentMethodId', paymentMethod.id);
                formData.append('coupon_code', 'coupon');
                let send_code;
                if (couponData?.coupon_detail?.length > 0) {
                    const { code, propagation_code } = couponData?.coupon_detail[0]
                    send_code = code || propagation_code;
                } else {
                    // send_code = couponId;
                }
                formData.append('coupon_code_id', send_code);


                const response = await axios.post(`${BASEURL}/api/user/customersubscription/`, formData);
                // const customerId = response?.data?.data?.customer_id;
                if (!response.data.error) {
                    setLoading(false);
                    setSuccesModal(true);
                    await AfterPaymentApi().then(() => {
                        setTimeout(() => {
                            setSuccesModal(false);
                            navigate('/home')
                        }, 2500);
                    })

                } else {
                    setLoading(false);
                    setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
                }
            }
        } catch (error) {
            console.error('Error:', error);
            // Handle the error appropriately, e.g., display an error message to the user.
        }
    }
    const AfterPaymentApi = async (product_id) => {
        let parseData = JSON.parse(localStorage.getItem("user"));
        const userId = parseData.id;
        const productId = couponData?.id;
        console.log(productId)
        const formDataToSend = new FormData();
        formDataToSend.append("user_id", userId);
        formDataToSend.append("package_id", product_id === undefined ? productId : product_id);
        try {
            const response = await axios.post(
                `${BASEURL}/api/user/package_done`,
                formDataToSend
            );
            setLoading(false)
            if (!response.data.error) {
                // navigate('/download-app')
            }
        } catch (error) {
            console.error(error);
        }
    }
    if (!couponDetail) {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }} >
                <div className="spinner-border spinner-border-sm text-light" role="status"></div>
            </div>
        )
    }
    return (
        <div>
            <section className="mainPremium">
                <div className="container-fluid">
                    <div className='coupon-payment-wrapper p-4 text-center'>
                        <p className='lightColor fs-6 mb-2'>Enter Payment Method</p>
                        <h2 className="pageTitle mb-2">{couponDetail?.title}</h2>
                        {/* <p className='lightColor fs-5 mb-2'>{couponDetail?.description} (Free 14 Day Trial)</p> */}
                        <p className='lightColor fs-5 mb-2'>{couponDetail?.description}</p>
                        {/* {
                            couponDetail?.duration_type === 'term_in_days' ?
                                <p className='lightColor fs-6 mb-2'>Term in days: {couponDetail?.term_in_days} Days </p>
                                :
                                <p className='lightColor fs-6 mb-2'>Free Trial Period: {couponDetail?.trial_period} Days </p>
                        } */}
                        <p className='lightColor fs-6 mb-2'>Payment Type: <span className='text-capitalize'>{`${couponDetail?.subscription_type}ly`}</span></p>
                        <p className='lightColor fs-6 mb-2 '>Price : ${couponDetail?.price} USD</p>
                        <div className='cardBox my-4'>
                            <CardElement />
                        </div>
                        {
                            couponPaymentError.error && <p className='my-2 text-danger'>{couponPaymentError.text}</p>
                        }
                        {
                            loading ? (
                                <div className='payNow text-center mt-4'>
                                    <button className="primaryButton customButton">
                                        <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                                    </button>
                                </div>
                            )
                                : (
                                    <div className='d-flex justify-content-center align-items-center gap-4  w-100'>
                                        <div className='payNow text-center mt-4 '>
                                            <CustomButton
                                                variant="primaryButton"
                                                text="Save"
                                                onClick={signUpAPi}
                                            />

                                        </div>
                                        <div className='payNow text-center mt-4 '>
                                            <CustomButton
                                                variant="primaryButton"
                                                text="Cancel"
                                                onClick={() => navigate("/home")}
                                            />
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </div>
            </section>
            <Modal
                show={paymentDecline}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="text-end ml-auto">
                    </div>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">Payment Declined!</h2>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                show={succesModal}
                centered
                className="success-modal"
                backdrop="static"
            >
                <Modal.Body>
                    <div className="p-5">
                        <h2 className="font-16 lh-16 my-5 fw-normal text-center">{couponDetail?.duration_type === "term_in_days" ? 'Paid' : 'Saved'} Successfully</h2>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PaymentScreen
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { playAudio, playMyMixer, playMyMixerObj } from '../../Store/Slices/SoundPlayerSlice';

import { FillPlay, FillPause } from '../../Assets/svg';

import { pauseMixer, playMixer } from '../../Store/Slices/MixerSlice';
import './style.css'
import { Link } from 'react-router-dom';
import { activeUser } from '../../Store/Slices/APIs';
import { ClearExternalMixData, handleMixerTray } from '../../Store/Slices/MyMixerSlice';
import Common from '../../Util/common';

const CustomAudioCard = ({ item, type }) => {

    const dispatch = useDispatch();
    const audio = useSelector((state) => state.soundPlayer.audio);
    const isPlaying = useSelector((state) => state.mixer.play);
    const mix = useSelector((state) => state.soundPlayer.mixObj);


    const [isPlayingState, setIsPlayingState] = useState(false)
    const [isActiveAudio, setIsActiveAudio] = useState(false)


    useEffect(() => {
        if (type === Common.FEATUREDMIXES) {
            const isActive = mix.id === item.id;
            setIsActiveAudio(isActive);
            setIsPlayingState(isActive && isPlaying);
        }
    }, [mix])

    useEffect(() => {
        if (type !== Common.FEATUREDMIXES) {
            const isActive = audio?.audio != undefined && audio?.audio == item?.audio;
            setIsActiveAudio(isActive);
            setIsPlayingState(isActive && isPlaying);
        }
    }, [audio])

    useEffect(() => {
        if (isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [isPlaying])

    const handlePlay = () => {
        if (isActiveAudio) {
            dispatch(isPlaying ? pauseMixer() : playMixer());
            setIsPlayingState(!isPlaying);
        } else {
            dispatch(activeUser());
            if (type == Common.FEATUREDMIXES && item?.sounds_list?.length > 0) {
                dispatch(handleMixerTray(true));
                dispatch(ClearExternalMixData());
                dispatch(playMyMixer(item?.sounds_list));
                dispatch(playMyMixerObj(item));
                setTimeout(() => {
                    dispatch(playMixer());
                }, 500);
            } else {
                dispatch(playAudio(item));
                dispatch(playMixer());
                setIsPlayingState(true);
            }
        }
    };


    return (
        <div className="custom-card-wrapper">
            <div className="custom-card">
                <img src={item.image || item?.banner} className="card-image" />
                <span className="button" onClick={handlePlay}>
                    {isPlayingState && isActiveAudio ? (<img src={FillPause} />) : (<img src={FillPlay} />)}
                </span>
            </div>
            <Link to={type === Common.FEATUREDMIXES ? `/featured-mixes/${item.id}` : `/audio-detail/${item.type == Common.RELAXATION ? Common.RELAXREST : item.type}/${item.id}`}>
                <h3 className="card-title mt-3 mb-1">{item?.title || item?.name}</h3>
            </Link>

        </div>
    )
}

export default CustomAudioCard

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./Store";
import './Util/axios'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
// import { disableReactDevTools } from '@fvilers/disable-react-devtools';


const stripePromise = loadStripe('pk_test_51O5qGLIYIT7NBGHBgIzH7O4P9OSGYL7fVOs9VU4oszgbQNowqjYDz8G77j6yNfQhRthmpyaLio0B8WVgQPFlEAgg00cn4uDsE1');
// const stripePromise = loadStripe('pk_live_51O5qGLIYIT7NBGHBpr7ZBOVIUF50u1GN2AnrSzyjzyOJVpSZjtWdFKJqLCiJ9cbXwQ4bkiBmbaRJFmRDT9prHwYV00LXbpwCTO');

// disableReactDevTools();


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Elements stripe={stripePromise}>
        <App />
    </Elements>           
  </Provider>               
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

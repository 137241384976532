// Enum-like object
const Common = Object.freeze({
    FEATUREDMIXES: 'Featured Mixes',
    RELAXATION: 'relaxation',
    RELAXANDREST: 'Relax & Rest',
    RELAXREST: 'relax-rest',
    MIXURE: "mixture"
});


export default Common;
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { playAudio, playMyMixer, playMyMixerObj } from '../../Store/Slices/SoundPlayerSlice';

import BASEURL from '../../Config/global';

import { FillPlay, FillPause, TrackedPlay } from '../../Assets/svg';

import { pauseMixer, playMixer } from '../../Store/Slices/MixerSlice';
import { Link } from 'react-router-dom';
import { activeUser } from '../../Store/Slices/APIs';
import { ClearExternalMixData, handleMixerTray } from '../../Store/Slices/MyMixerSlice';
import Common from '../../Util/common';

const AudioStrip = ({ item, type }) => {

    const dispatch = useDispatch();
    const audio = useSelector((state) => state.soundPlayer.audio);
    const isPlaying = useSelector((state) => state.mixer.play);
    const mix = useSelector((state) => state.soundPlayer.mixObj);


    const [isPlayingState, setIsPlayingState] = useState(false)
    const [isActiveAudio, setIsActiveAudio] = useState(false)


    useEffect(() => {
        // if mix id equals to item id -> 'true false active audio'
        // or if isPlaying state is true -> 'true playing state' -> 'false playing state'

        // if (mix.id == item.id) {
        //     setIsActiveAudio(true);
        //     if (isPlaying) {
        //         setIsPlayingState(true);
        //     } else {
        //         setIsPlayingState(false);
        //     }
        // } else {
        //     setIsActiveAudio(false);
        //     setIsPlayingState(false);
        // }

        // Refactor version of the above code
        if (type === Common.FEATUREDMIXES) {
            const isActive = mix.id === item.id;
            setIsActiveAudio(isActive);
            setIsPlayingState(isActive && isPlaying);
        }

    }, [mix])

    useEffect(() => {
        // // it will be called when the relaxation/stories/articles are playing
        if (type !== Common.FEATUREDMIXES) {
            const isActive = audio?.audio != undefined && audio?.audio == item?.audio;
            setIsActiveAudio(isActive);
            setIsPlayingState(isActive && isPlaying);
        }
    }, [audio])


    // while working on the featured mixes module i have commented some code here and test the better approach [11/19/2024]

    // useEffect(() => {
    //     if (mix.id == item.id && isPlaying) {
    //         setIsPlayingState(true)
    //     }
    //     else {
    //         setIsPlayingState(false)
    //     }
    // }, [mix])

    // // it will be called when the relaxation/stories/articles are playing
    // useEffect(() => {
    //     if (audio.audio != undefined && audio.audio == item.audio) {
    //         setIsActiveAudio(true)
    //     }
    //     else {
    //         setIsActiveAudio(false)
    //     }
    // }, [audio])

    // // it will be called when the relaxation/stories/articles are playing
    // useEffect(() => {
    //     if (audio.audio != undefined && audio.audio == item.audio && isPlaying) {
    //         setIsPlayingState(true)
    //     }
    //     else {
    //         setIsPlayingState(false)
    //     }
    // }, [audio])

    useEffect(() => {
        if (isPlaying) {
            setIsPlayingState(true)
        }
        else {
            setIsPlayingState(false)
        }
    }, [isPlaying])

    const handlePlay = () => {
        if (isActiveAudio) {

            // if (isPlaying) {
            //     dispatch(pauseMixer());
            //     setIsPlayingState(false);
            // } else {
            //     dispatch(playMixer());
            //     setIsPlayingState(true);
            // }

            // Refactor version of the above code
            dispatch(isPlaying ? pauseMixer() : playMixer());
            setIsPlayingState(!isPlaying);

        } else {
            dispatch(activeUser());
            if (type == Common.FEATUREDMIXES && item?.sounds_list?.length > 0) {
                dispatch(handleMixerTray(true));
                dispatch(ClearExternalMixData());
                dispatch(playMyMixer(item?.sounds_list));
                dispatch(playMyMixerObj(item));
                setTimeout(() => {
                    dispatch(playMixer());
                }, 500);
            } else {
                dispatch(playAudio(item));
                dispatch(playMixer());
                setIsPlayingState(true);
            }
        }
    };


    return (
        <div className="audioStrip">
            <Link to={type === Common.FEATUREDMIXES ? `/featured-mixes/${item.id}` : `/audio-detail/${item.type == Common.RELAXATION ? Common.RELAXREST : item.type}/${item.id}`}
                className="audioStripDetails">
                <img src={item?.thumbnail || item?.banner} alt="" />
                <p>{item?.title || item?.name}</p>
            </Link>
            <div className="audioStripExtra">
                <p className="audioStripduration">{item?.tag && item?.tag}</p>
                <button type="button" className="audioStripButton audioStripPlay" onClick={handlePlay}>
                    {isPlayingState && isActiveAudio ? (<img src={FillPause} />) : (<img src={TrackedPlay} />)}
                </button>
            </div>
        </div>
    )
}

export default AudioStrip

import React, { useState } from "react";
import ReactPlayer from "react-player";

const Button = ({ playing, click }) => {
    return (
        <>
            {playing ? (
                <button
                    className="playButton playerAction active pause-btn"
                    onClick={click}
                >
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="Media / Pause">
                            <g id="Vector">
                                <path d="M15 5.5V18.5C15 18.9647 15 19.197 15.0384 19.3902C15.1962 20.1836 15.816 20.8041 16.6094 20.9619C16.8026 21.0003 17.0349 21.0003 17.4996 21.0003C17.9642 21.0003 18.1974 21.0003 18.3906 20.9619C19.184 20.8041 19.8041 20.1836 19.9619 19.3902C20 19.1987 20 18.9687 20 18.5122V5.48777C20 5.03125 20 4.80087 19.9619 4.60938C19.8041 3.81599 19.1836 3.19624 18.3902 3.03843C18.197 3 17.9647 3 17.5 3C17.0353 3 16.8026 3 16.6094 3.03843C15.816 3.19624 15.1962 3.81599 15.0384 4.60938C15 4.80257 15 5.03534 15 5.5Z" stroke="#f7ba31" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M4 5.5V18.5C4 18.9647 4 19.197 4.03843 19.3902C4.19624 20.1836 4.81599 20.8041 5.60938 20.9619C5.80257 21.0003 6.0349 21.0003 6.49956 21.0003C6.96421 21.0003 7.19743 21.0003 7.39062 20.9619C8.18401 20.8041 8.8041 20.1836 8.96191 19.3902C9 19.1987 9 18.9687 9 18.5122V5.48777C9 5.03125 9 4.80087 8.96191 4.60938C8.8041 3.81599 8.18356 3.19624 7.39018 3.03843C7.19698 3 6.96465 3 6.5 3C6.03535 3 5.80257 3 5.60938 3.03843C4.81599 3.19624 4.19624 3.81599 4.03843 4.60938C4 4.80257 4 5.03534 4 5.5Z" stroke="#f7ba31" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                        </g>
                    </svg>
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Play</p>
                </button>
            ) : (
                <button
                    className="playButton playerAction active play-btn"
                    onClick={click}
                >
                    <svg width="20px" height="20px" id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.57 65.13">
                        <g id="Layer_1-2">
                            <path fill="none" stroke="#25e544" strokeWidth={'5px'} strokeMiterlimit={10} d="m47.18,36.15L9.26,61.88c-2.87,1.95-6.76-.11-6.76-3.58V6.83c0-3.47,3.88-5.53,6.76-3.58l37.92,25.73c2.53,1.72,2.53,5.44,0,7.16Z" />
                        </g>
                    </svg>
                    <p className="playerActionText mt-1" style={{ fontSize: "10px", fontWeight: "300", width: "32px" }}>Play</p>
                </button>
            )}
        </>
    )
}

const AudioPlayer = () => {
    const [tracks, setTracks] = useState([
        { id: 1, title: "Birds Sound", url: "https://cdn.pixabay.com/audio/2024/09/13/audio_7ae0f8b6e4.mp3", volume: 0.5, playing: false, loop: true },
        { id: 2, title: "Quack Continous", url: "https://commondatastorage.googleapis.com/codeskulptor-assets/sounddogs/missile.mp3", volume: 0.5, playing: false, loop: true },
        { id: 3, title: "Awakening Soul", url: "https://storage.googleapis.com/relax_prod/assets/ETH-0009_-_Awakening_Soul__-20_dB_LUFS.ogg", volume: 0.5, playing: false, loop: true },
    ]);

    const [newTrackUrl, setNewTrackUrl] = useState("");
    const [_isPlaying, setPlaying] = useState(false);

    // Global play/pause for all tracks
    const globalPlayPause = (id) => {
        setTracks((prevTracks) =>
            prevTracks.map((track) => ({
                ...track,
                playing: !_isPlaying,
            }))
        );
        setPlaying(!_isPlaying);
    };

    // Toggle play/pause for a track
    const togglePlayPause = (id) => {
        setTracks((prevTracks) =>
            prevTracks.map((track) =>
                track.id === id ? { ...track, playing: !track.playing } : track
            )
        );
    };

    // Adjust volume for a track
    const changeVolume = (id, volume) => {
        setTracks((prevTracks) =>
            prevTracks.map((track) =>
                track.id === id ? { ...track, volume } : track
            )
        );
    };

    // Toggle loop for a track
    const toggleLoop = (id) => {
        setTracks((prevTracks) =>
            prevTracks.map((track) =>
                track.id === id ? { ...track, loop: !track.loop } : track
            )
        );
    };

    // Remove a track
    const removeTrack = (id) => {
        setTracks((prevTracks) => prevTracks.filter((track) => track.id !== id));
    };

    // Add a new track
    const addTrack = () => {
        if (newTrackUrl.trim()) {
            const newId = tracks.length > 0 ? tracks[tracks.length - 1].id + 1 : 1;
            setTracks([
                ...tracks,
                { id: newId, url: newTrackUrl, volume: 0.5, playing: false, loop: false },
            ]);
            setNewTrackUrl("");
        }
    };

    return (
        <div style={{ padding: "20px" }}>
            <h2>Enhanced Multi-Sound Audio Player</h2>

            {/* Add Track Input */}
            <div className="d-flex align-items-end flex-wrap" style={{ marginBottom: "20px" }}>
                <input
                    type="text"
                    placeholder="Enter track URL"
                    value={newTrackUrl}
                    onChange={(e) => setNewTrackUrl(e.target.value)}
                    style={{ width: "300px", marginRight: "10px" }}
                />
                <button onClick={addTrack}>Add Track</button>
                <button onClick={globalPlayPause}>{_isPlaying ? "Pause" : "Play"}</button>
                {/* <Button playing={_isPlaying} click={globalPlayPause} /> */}
            </div>

            {tracks.map((track) => (
                <div key={track.id} style={{ marginBottom: "20px", border: "1px solid #ccc", padding: "10px" }}>
                    <h4>{track.title}</h4>
                    <div>
                        <button onClick={() => togglePlayPause(track.id)}>
                            {track.playing ? "Pause" : "Play"}
                        </button>
                        <button onClick={() => toggleLoop(track.id)}>
                            {track.loop ? "Disable Loop" : "Enable Loop"}
                        </button>
                        <button onClick={() => removeTrack(track.id)} style={{ marginLeft: "10px", color: "red" }}>
                            Remove
                        </button>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                        <input
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={track.volume}
                            onChange={(e) => changeVolume(track.id, parseFloat(e.target.value))}
                        />
                        <span style={{ marginLeft: "10px" }}>Volume: {Math.round(track.volume * 100)}%</span>
                    </div>
                    {/* Hidden ReactPlayer for each track */}
                    <ReactPlayer
                        url={track.url}
                        playing={track.playing}
                        volume={track.volume}
                        loop={track.loop}
                        controls={true}
                        height="60px"
                        width="100%"
                    />
                </div>
            ))}
        </div>
    );
};

export default AudioPlayer;

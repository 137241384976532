import "./style.css";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeSound, playSound, playAudio, removeAudio, removeMusic, playMusic, playMyMixer, playMyMixerObj, removeMix } from "../../Store/Slices/SoundPlayerSlice";
import { activeUser } from "../../Store/Slices/APIs";
import { ClearExternalMixData, handleMixerTray } from "../../Store/Slices/MyMixerSlice";
import { pauseMixer, playMixer } from "../../Store/Slices/MixerSlice";
import Common from "../../Util/common";

const CustomCard = (props) => {
  const sounds = useSelector((state) => state.soundPlayer.sounds);
  const music = useSelector((state) => state.soundPlayer.music);
  const audio = useSelector((state) => state.soundPlayer.audio);
  const _currentPlayingMix = useSelector((state) => state.soundPlayer.mixObj);

  const [isLoading, setIsLoading] = useState(true);
  const [addClass, setAddClass] = useState(false);
  const [addMusicClass, setAddMusicClass] = useState(false);
  const [addMixClass, setAddMixClass] = useState(false);

  const dispatch = useDispatch();


  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const dispatchRemoveSound = (item) => {
    dispatch(removeSound(item))
  }

  const dispatchPlaySound = (item) => {
    if (item.soundcategory === 164) {
      if (addMusicClass) {
        dispatch(removeMusic());
        setAddMusicClass(false)
      } else {
        dispatch(activeUser());
        dispatch(playMusic(item));
        dispatch(playMixer())
      }
    } else {
      dispatch(activeUser());
      dispatch(playSound(item));
      dispatch(playMixer())
    }
    // dispatch(playSound(item))
  }

  const dispatchPlayAudio = (item) => {
    dispatch(activeUser());
    dispatch(playAudio(item))
    dispatch(playMixer())
  }

  const dispatchRemoveAudio = (item) => {
    dispatch(removeAudio(item))
  }

  const dispatchMix = (sound) => {
    if (addMixClass) {
      dispatch(removeMix());
      dispatch(pauseMixer());
    } else {
      debugger
      dispatch(handleMixerTray(true));
      dispatch(ClearExternalMixData());
      dispatch(activeUser());
      dispatch(playMyMixer(sound.sounds_list));
      dispatch(playMyMixerObj(sound));
      setTimeout(() => {
        dispatch(playMixer());
      }, 500);
    }
  }

  useEffect(() => {

    if (sounds) {
      const soundExists = sounds.some((sound) => sound?.id === props?.item?.id);
      setAddClass(soundExists)
    }

    if (audio) {
      if (audio?.id == props?.item?.id) {
        setAddClass(true)
      }
    }
  }, [sounds, music, audio])

  useEffect(() => {
    if (music?.id == props?.item?.id) {
      setAddMusicClass(true)
    } else {
      setAddMusicClass(false)
    }
  }, [music])

  useEffect(() => {
    if (_currentPlayingMix?.id == props?.item?.id) {
      setAddMixClass(true)
    } else {
      setAddMixClass(false)
    }
  }, [_currentPlayingMix])

  return (
    <>
      {/* {isLoading && <div className="customCardSkeleton skeleton"></div>} */}
      <div
        className={`customCard ${(addClass || addMusicClass || addMixClass) ? 'grendientBox' : ''}`}
        onClick={() => {
          if (props?.type == Common.FEATUREDMIXES && props?.item?.sounds_list?.length > 0) {
            dispatchMix(props?.item)
          } else {
            if (props?.item?.type == 'sounds') {
              addClass ? dispatchRemoveSound(props?.item) : dispatchPlaySound(props?.item)
            } else {
              addClass ? dispatchRemoveAudio(props?.item) : dispatchPlayAudio(props?.item)
            }
          }
        }}
      // onClick={props.onClick}
      >
        <img
          src={props?.image}
          alt={props?.alt}
          className="customCardImage"
        />
        <div className="customCardOverlay"></div>
        <h3 className="customCardTitle">{props?.title}</h3>
      </div>
    </>
  );
};

export default CustomCard;

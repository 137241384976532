import React, { useEffect, useState } from 'react'
import { PaymentForm } from '../Upgrade/PaymentForm'
import { Link, useNavigate } from 'react-router-dom'

import "../Main/style.css";
import CustomButton from '../../Components/CustomButton';
import CustomInput from '../../Components/CustomInput';
import { Modal, Spinner } from 'react-bootstrap';
import BASEURL from '../../Config/global';
import axios from 'axios';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Signup from '../Auth/Signup';
import { crossIcon } from '../../Assets/svg';
import "./style.css"
import { logo } from '../../Assets/images';
import { useParams } from 'react-router'
import { useSelector } from 'react-redux';
import PromoPush from '../../Components/PromoPush';

const CouponSubscription = () => {
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [paymentModalIsInProgress, setPaymentModalIsInProgress] = useState(false);
  const [id, setId] = useState(1);

  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState();
  const [succesModal, setSuccesModal] = useState(false);
  const [paymentDecline, setPaymentDecline] = useState(false);
  const [SignUpForm, setSignUpForm] = useState(false);
  const [showPaymentUI, setShowPaymentUI] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [couponData, setCouponData] = useState([]);
  const [couponDetail, setCouponDetail] = useState([]);
  const [couponError, setCouponError] = useState({ error: false, text: "" });
  const [couponPaymentError, setCouponPaymentError] = useState({ error: false, text: "" });
  const [loginModal, setLoginModal] = useState(false);
  const [promoCodeLimitReachedModal, setPromoCodeLimitReachedModal] = useState(false);
  const [termInDaysModal, setTermInDaysModal] = useState(false);
  // const params = new URLSearchParams(window.location.search);
  const [newCouponData, setNewCouponData] = useState({});
  const { couponId } = useParams();
  const [showPushPage, setShowPushPage] = useState(true)

  // Clear Session while doing refresh
  useEffect(() => {
    const clearSessionStorage = () => {
      sessionStorage.clear();
    };
    window.addEventListener('beforeunload', clearSessionStorage);
    return () => {
      window.removeEventListener('beforeunload', clearSessionStorage);
    };
  }, []);

  useEffect(() => {
    if (couponId) {
      handleSpecialSubscription(couponId);
    } else {
      setLoading(false);
      setSignUpForm(true);
    }
  }, [])

  const handleSpecialSubscription = async (code) => {
    setLoading(true)
    const API = `${BASEURL}/api/user/coupon/${code}`
    const coupon_response = await axios.get(API);
    if (coupon_response.data.status === 200) {
      const coupon_response_data = coupon_response.data.data;
      if (coupon_response_data.length > 0) {
        setNewCouponData(coupon_response_data[0])
      } else {
        setNewCouponData(coupon_response_data)
      }
      const isPromoUser = window.sessionStorage.getItem("isPromoUser")
      if (!isPromoUser) {
        setLoading(false)
        setSignUpForm(true)
      }
    } else {
      if (coupon_response.data.error) {
        setCouponError({
          error: true,
          text: coupon_response.data.message
        })
        setCouponModal(true)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    const checkUser = JSON.parse(localStorage.getItem("user"));
    const isPromoUser = window.sessionStorage.getItem("isPromoUser")

    if (checkUser?.id) {
      if (token) {
        navigate('/home')
      } else if (isPromoUser) {
        setSignUpForm(false);
        setLoading(true)
        if (checkUser?.coupon && checkUser?.coupon.hasOwnProperty("id")) {
          setCouponDetail(checkUser?.coupon);
          setShowPushPage(false);
          setCouponModal(false);
          getCouponDataByApi(checkUser?.coupon?.id, "no-modal")
        } else {
          setLoading(false)
          setCouponModal(true);
        }
      }
    }

    return () => {
      sessionStorage.clear();
    };

  }, [])

  const handleClick = async (e) => {
    if (formData.coupon) {
      setCouponError({ error: false, text: "" });
      getCoupon(formData.coupon, "show-modal");
    }
    if (newCouponData) {
      setCouponError({ error: false, text: "" });
      getCoupon(couponId, "show-modal");
    }
    if (couponError?.error) {
      navigate(couponError?.redirect ? couponError?.redirect : '/freetrial')
    }
  }

  const getCouponDataByApi = async (id, status) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASEURL}/api/user/get_coupon?coupon_id=${id}`);
      if (!response.data.error) {
        setCouponData(response.data.data[0]);
        setCouponDetail(response.data.data[0].coupon_detail[0]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setCouponError({ error: true, text: "Promo invalid" });
    }
  }

  const getCoupon = async (code, status) => {
    setLoading(true);
    let parseData = JSON.parse(localStorage.getItem("user"));
    const userEmail = parseData.email;

    if (!code) {
      return
    }

    try {
      const response = await axios.post(
        `${BASEURL}/api/user/get_coupon`,
        {
          "email": userEmail,
          "code": code
        }
      );
      if (!response.data.error) {
        if (response.data.data[0].price === "0" || response.data.data[0].price === "0.00" || response.data.data[0].price === "0.0") {
          setCouponData(response.data.data[0]);
          await AfterPaymentApi(response.data.data[0].id).then((result) => {
            setCouponModal(false);
            setLoading(false);
            setLoginModal(false);
            navigate('/download-app');
          });
        } else {
          setCouponData(response.data.data[0]);
          const coupon_details = response?.data?.data?.length > 0 ? response.data.data[0] : {};
          let obj = {};
          if (coupon_details?.subscription_user_propagate) {
            obj = {
              "title": coupon_details?.propagation_title,
              "price": coupon_details?.propagation_price,
              "description": coupon_details?.propagation_description,
              "subscription_type": coupon_details?.propagation_subscription_type,
              "duration_type": coupon_details?.propagation_duration_type,
              "trial_period": coupon_details?.propagation_trial_period,
              "term_in_days": coupon_details?.propagation_term_in_days,
            }
            setCouponDetail(obj);
          } else {
            setCouponDetail(coupon_details.coupon_detail[0]);
          }
          setLoading(false);

          if (status !== "no-modal") {
            const { duration_type, term_in_days } = response?.data?.data?.[0]?.['coupon_detail']?.length > 0 ? response?.data?.data?.[0]?.['coupon_detail'][0] : obj;
            if (duration_type === "term_in_days" && term_in_days !== "0") {
              setTermInDaysModal(true);
            } else {
              setCouponModal(false);
            }
          }
        }
      } else {
        if (response.data.message === "Coupon using Limit is reached.") {
          if (status !== "no-modal") {
            setPromoCodeLimitReachedModal(true);
            setLoading(false);
          }
        } else {
          let redirect = '/freetrial'
          if (response?.data?.message === "Propagation is not allowed for this subscription") {
            if (response.data.data?.length > 0 && response.data.data[0]?.show_packages) {
              redirect = '/subscription'
            }
          }
          setCouponError({ error: true, text: response?.data?.message ? response?.data?.message : "coupon invalid" , redirect});
          setLoading(false);
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setCouponError({ error: true, text: "coupon invalid" });
      // Handle the error appropriately, e.g., display an error message to the user.
    }
  }

  const stripe = useStripe()
  const elements = useElements()

  async function PackageDetails() {
    try {
      const response = await axios.get(`${BASEURL}/api/user/packages/${id}`);
      if (response.data.error != true) {
        console.log(response?.data?.data[0])
        setPaymentData(response?.data?.data[0])
        // console.log(response)
      } else {
        console.log(response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // PackageDetails();
  }, [])

  // payment form integration 
  async function signUpAPi() {
    setLoading(true);
    setPaymentModalIsInProgress(true)
    setCouponPaymentError({ error: false, text: "" });

    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardElement);

      // Create a PaymentMethod and attach it to the customer
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      
      if (error) {
        setLoading(false);
        setPaymentModalIsInProgress(false)
        if (error.message.includes('Invalid API Key provided:')) {
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
        } else {
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
          // setCouponPaymentError({ error: true, text: error.message });
          console.error(error.message);
          setPaymentDecline(true);
          setTimeout(() => {
            setPaymentDecline(false);
          }, 2500);
        }
        // setCouponPaymentError({ error: true, text: error.message });
      } else {
        // Attach the PaymentMethod to the customer
        // const obj = {
        //   customerId: customerId,
        //   paymentMethodId: paymentMethod.id
        // }

        let parseData = JSON.parse(localStorage.getItem("user"));
        const userEmail = parseData.email;
        const productId = paymentData?.package_price_id;
        const { propagation_trial_period, subscription_user_propagate , coupon_detail} = couponData
        const planId = subscription_user_propagate ? couponData?.propagation_plan_id : couponData?.plan_id;
        // const trial_period = couponDetail?.trial_period;
        const formData = new FormData();
        formData.append('email', userEmail);
        formData.append('plan_id', planId);
        if (couponData.propagation_duration_type === 'trial_period' && Number(couponData.propagation_trial_period) > 0) {
          formData.append('trial_period', couponData.propagation_trial_period);
        }
        else if (couponData.propagation_duration_type === 'term_in_days' && Number(couponData.propagation_term_in_days) > 0) {
          formData.append('trial_period', couponData.propagation_term_in_days);
        }
        formData.append('platform', "web");
        formData.append('paymentMethodId', paymentMethod.id);
        formData.append('coupon_code', 'coupon');
        let send_code;
        if (couponData?.coupon_detail?.length > 0) {
          const { code, propagation_code } = couponData?.coupon_detail[0]
          send_code = code || propagation_code;
        } else {
          send_code = couponId;
        }
        formData.append('coupon_code_id', send_code);


        const response = await axios.post(`${BASEURL}/api/user/customersubscription/`, formData);
        // const customerId = response?.data?.data?.customer_id;
        if (!response.data.error) {
          setLoading(false);
          setSuccesModal(true);
          await AfterPaymentApi().then(() => {
            setTimeout(() => {
              setSuccesModal(false);
              navigate('/download-app')
            }, 2500);
          })

        } else {
          setLoading(false);
          setCouponPaymentError({ error: true, text: 'Invalid Card Details' });
        }
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle the error appropriately, e.g., display an error message to the user.
    }
  }

  const AfterPaymentApi = async (product_id) => {
    let parseData = JSON.parse(localStorage.getItem("user"));
    const userId = parseData.id;
    const productId = couponData?.id;
    console.log(productId)
    const formDataToSend = new FormData();
    formDataToSend.append("user_id", userId);
    formDataToSend.append("package_id", product_id === undefined ? productId : product_id);
    try {
      const response = await axios.post(
        `${BASEURL}/api/user/package_done`,
        formDataToSend
      );
      setLoading(false)
      if (!response.data.error) {
        // navigate('/download-app')
      }
    } catch (error) {
      console.error(error);
    }
  }

  const closePopup = () => {
    setSignUpForm(false);
    setShowPaymentUI(true);
    setCouponModal(true);
  }

  const handleContinueButton = () => {
    setShowPushPage(!showPushPage)
  }

  if (showPushPage && window.location.pathname.includes('/promo') && !window.location.pathname.includes('/promoint')) {
    return (<PromoPush handleContinueButton={handleContinueButton} />)
  }

  if (loading && !paymentModalIsInProgress) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  }
  return (
    <div>
      <section className="mainPremium">
        <div className="container-fluid">

          <div className="row flex-column">
            <div className='col-12'>
              {
                (!couponModal && !SignUpForm) &&
                <div className='coupon-payment-wrapper p-4 text-center'>
                  <p className='lightColor fs-6 mb-2'>Enter Payment Method</p>
                  <h2 className="pageTitle mb-2">{couponDetail?.title}</h2>
                  {/* <p className='lightColor fs-5 mb-2'>{couponDetail?.description} (Free 14 Day Trial)</p> */}
                  <p className='lightColor fs-5 mb-2'>{couponDetail?.description}</p>
                  {
                    couponDetail?.duration_type === 'term_in_days' ?
                      <p className='lightColor fs-6 mb-2'>Term in days: {couponDetail?.term_in_days} Days </p>
                      :
                      <p className='lightColor fs-6 mb-2'>Free Trial Period: {couponDetail?.trial_period} Days </p>
                  }
                  <p className='lightColor fs-6 mb-2'>Payment Type: <span className='text-capitalize'>{`${couponDetail?.subscription_type}ly`}</span></p>
                  <p className='lightColor fs-6 mb-2 '>Price : ${couponDetail?.price} USD</p>
                  <div className='cardBox my-4'>
                    <CardElement />
                  </div>
                  {
                    couponPaymentError.error && <p className='my-2 text-danger'>{couponPaymentError.text}</p>
                  }
                  {
                    loading ? (
                      <div className='payNow text-center mt-4'>
                        <button className="primaryButton customButton">
                          <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                        </button>
                      </div>
                    )
                      : (
                        <div className='payNow text-center mt-4'>
                          <CustomButton
                            variant="primaryButton"
                            text="Save"
                            onClick={signUpAPi}
                          />
                        </div>
                      )
                  }
                </div>
              }
            </div>
          </div>

          <Modal
            show={couponModal}
            centered
            className="coupon-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className='py-5'>
                <img src={logo} alt="Logo" className='logo' />
                {/* <p className='lightColor fs-5 my-4 text-center'>Please enter coupon code</p> */}
                {
                  couponId ?
                    <div>
                      <p className='font-16 fw-bold text-center mt-5'>Promo Code: {couponId?.toUpperCase()}</p>
                      <p className='fw-light text-center small'>{newCouponData?.code_description}</p>
                    </div>
                    :
                    <div className='field-wrapper mt-5'>
                      <CustomInput
                        placeholder={''}
                        id="coupon"
                        type="text"
                        labelClass="mainLabel"
                        inputClass="mainInput text-center"
                        label="Promo Code"
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            coupon: event.target.value,
                          });
                        }}
                      />
                    </div>
                }

                {couponError.error == true && (
                  <p className="smallText lightColor mb-0 text-center mt-2">{couponError.text}</p>
                )}
                {
                  loading ? (
                    <div className='payNow text-center mt-5'>
                      <button className="primaryButton customButton">
                        <div className="spinner-border spinner-border-sm text-dark" role="status"></div>
                      </button>
                    </div>
                  )
                    : (
                      <div className='payNow text-center mt-5'>
                        <CustomButton
                          variant="primaryButton"
                          text={couponId ? "Continue" : "Submit"}
                          onClick={handleClick}
                        />
                      </div>
                    )
                }
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={SignUpForm}
            centered
            className="signform-modal"
            backdrop="static"
          >
            <Modal.Body>
              <Signup newCouponData={newCouponData} coupon={"coupon"} closeCouponSignup={closePopup} />
            </Modal.Body>
          </Modal>

          {/* success modal */}
          <Modal
            show={succesModal}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">{couponDetail?.duration_type === "term_in_days" ? 'Paid' : 'Saved'} Successfully, <br />  Please Login</h2>
              </div>
            </Modal.Body>
          </Modal>

          {/* decline modal */}
          <Modal
            show={paymentDecline}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="text-end ml-auto">
              </div>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">Payment Declined!</h2>
              </div>
            </Modal.Body>
          </Modal>

          {/* success modal */}
          <Modal
            show={loginModal}
            centered
            className="success-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 my-5 fw-normal text-center">Thank you! Please Login</h2>
              </div>
            </Modal.Body>
          </Modal>

          {/* promo code reached modal */}
          <Modal
            show={promoCodeLimitReachedModal}
            centered
            className="success-modal blur-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 mt-5 fw-normal text-center">Invalid Promo</h2>
                <div className='text-center mt-4'>
                  <CustomButton
                    variant="primaryButton"
                    text="Ok"
                    onClick={() => navigate('/Login')}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>

          {/* term in days modal */}
          <Modal
            show={termInDaysModal}
            centered
            className="success-modal blur-modal"
            backdrop="static"
          >
            <Modal.Body>
              <div className="p-5">
                <h2 className="font-16 lh-16 mt-5 fw-normal text-center">You have {couponDetail?.term_in_days} free days to use Relax Scofa, enjoy it!</h2>
                <div className='text-center mt-4'>
                  <CustomButton
                    variant="primaryButton"
                    text="Login"
                    onClick={() => navigate('/download-app')}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>

        </div>
      </section>
    </div>
  )
}

export default CouponSubscription
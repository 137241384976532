import { createSlice } from "@reduxjs/toolkit";
import BASEURL from "../../Config/global";

const MyMixerSlice = createSlice({
  name: "mymixer",
  initialState: {
    isMixespp: false,
    isMixesId: null,
    isMixPause: null,
    isMixerPlay: false,
    isMixerPause: false,
    pauseMyMixer: false,
    playMyMixer: false,
    player: [],
    externalMixList: [],
    isMixerTrayOpen: false
  },
  reducers: {
    PlayMyMix(state, action) {
      console.log(action.payload)
      state.player = action.payload;
    },
    ClearMyMix(state, action) {
      console.log("clear mix")
      state.player = [];
    },
    PauseMyMixer(state, action) {
      state.isMixespp = true;
    },
    PlayMyMixer(state, action) {
      state.isMixespp = false;
    },
    PlayMyMixId(state, action) {
      state.isMixesId = action.payload;
    },
    PauseMixer(state, action) {
      console.log("In redux mixer")
      state.pauseMyMixer = true;
    },
    PlayMixer(state, action) {
      state.pauseMyMixer = false;
      state.playMyMixer = false;
      state.isMixerPause = false;
      state.isMixerPlay = true;
    },
    PauseMixer(state, action) {
      console.log("In redux mixer")
      state.pauseMyMixer = false;
      state.playMyMixer = false;
      state.isMixerPlay = false;
      state.isMixerPause = true;
    },
    ClearMixer(state, action) {
      state.isMixerPause = false;
      state.isMixerPlay = false;
      state.pauseMyMixer = false;
      state.playMyMixer = false;
    },
    ExternalMix(state, action) {
      state.externalMixList = action.payload;
    },
    ClearExternalMixData(state, action) {
      state.externalMixList = [];
    },
    handleMixerTray(state, action) {
      state.isMixerTrayOpen = action.payload;
    }
  },
});

export default MyMixerSlice.reducer;
export const { PlayMixer, PauseMixer, PauseMyMixer, PlayMyMixer, ClearMixer, PlayMyMix, ClearMyMix, PlayMyMixId, ExternalMix, ClearExternalMixData, handleMixerTray } = MyMixerSlice.actions;

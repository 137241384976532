import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router';
import BASEURL from '../../Config/global';
import axios from 'axios';
import { crossIcon } from '../../Assets/svg';
import CustomButton from '../CustomButton';
import './style.css'
import { moonCircle } from '../../Assets/images';
import { Link } from 'react-router-dom';
import { setCheckElegibility, setShowPaywallModal } from '../../Store/Slices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
const UserRestriction = () => {
  const navigate = useNavigate();
  const [restrictionModal, setRestrictionModal] = useState(false);
  const showPaywallModal = useSelector((state) => state.user.showPaywallModal);
  const dispatch = useDispatch();

  // const [showMessage, setShowMessage] = useState('Your Full Access trial has ended. Free home page content is still available.');
  const [redirectUrl, setRedirectUrl] = useState('');

  const location = useLocation()

  useEffect(() => {
    checkUserAccessibiliy()
  }, [location.pathname])
  
  useEffect(() => {
    if (showPaywallModal) {
      setRestrictionModal(showPaywallModal)
    }
  }, [showPaywallModal])

  const checkUserAccessibiliy = async () => {
    const user = window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user")) : null;
    if (!user) {
      return;
    }
    const { id } = user;
    const path = location.pathname;
    const response = await axios.get(`${BASEURL}/api/user/get_user_eligibility_on_status_basis/${id}/`);
    if (!response.data.error && response.data.data[0]) {
      const { eligibility, status } = response.data.data[0];
      dispatch(setCheckElegibility(eligibility));
      if (status === "coupon_lead" || status === "lead") {
        if (status === "lead") {
          setRedirectUrl("/upgrade-subscription")
        } else {
          setRedirectUrl("/payment")
        }
      }
      const checkAllowedRoutes = ['home', 'upgrade-subscription', 'payment', 'specials', 'settings', 'edit-profile', 'more-options', 'privacy-policy', 'terms-of-service'];
      if (!eligibility && !checkAllowedRoutes.includes(path?.split('/')[1])) {
        setRestrictionModal(true)
      }
    }
  }
  const handleAction = (path) => {
    setRestrictionModal(false)
    dispatch(setShowPaywallModal(false))
    navigate(path, { replace: true })
  }
  return (
    <Modal
      show={restrictionModal}
      centered
      className="unlock-modal"
      backdrop="static"
    >
      <Modal.Body>
        <div className="modal-wrapper d-flex flex-column position-relative">
          <div className="save-mix-header text-end position-absolute top-0 w-100 ">
            <button
              className="notButton ms-auto d-flex align-items-center  "
              style={{ paddingRight: '15px', paddingTop: '18px' }}
              onClick={() => handleAction("/home")}
            >
              <img src={crossIcon} alt="Close Button" className="img-fluid" style={{ width: '12px' }} />
            </button>
          </div>
          <div className="d-flex flex-column  justify-content-around  ">
            <div className="earn-description-wrapper p-1 p-lg-5 pb-lg-0">
              <div className="unlock-img text-center mt-5 mt-lg-0 caption-text">
                <h4>Upgrade to Premium &<br className='d-lg-none' /> Unlock All Content!</h4>
                <div className='d-lg-none mt-4 '>
                  <img src={moonCircle} />
                </div>
              </div>
              <div className='d-flex flex-column w-100 gap-4 gap-lg-1 justify-content-start px-1' >
                <div className='d-flex align-items-start'>
                  <div className="text-start w-100 text-desc my-3 my-lg-3 " >
                    <div className='px-4 px-lg-0 text-start'>
                    {<p className="fw-bold text-start text-lg-start">Your Full Access trial has ended.<br />Free home page content is still available.</p>}
                      <ul className=" list-style-unlock">
                        <ListItem text={"Unlock All Content with Premium"} />
                        <ListItem text={"Fresh New Content Added Weekly"} />
                        <ListItem text={<><span>Share the Experience—Invite <br className='d-lg-none'></br> Your Entire Household</span></>} />
                      </ul>
                      <p className='my-3 fw-semibold'> Upgrade now to enjoy:</p>
                      <ul className="list-style-unlock">
                        <ListItem text={"Library of Soothing Soundscapes"} />
                        <ListItem text={"Sleep Stories and Guided Meditations"} />
                        <ListItem text={"Relaxation Tailored for All"} />
                      </ul>
                      <p className='my-2 fw-semibold'>Take the next step in your wellness journey!</p>
                    </div>

                  </div>
                  <div className='d-none d-lg-block mt-5'>
                    <img src={moonCircle} style={{ width: '178px' }} />
                  </div>
                </div>
                <div className='my-5 px-4 px-lg-1'>
                  <div className="w-100  d-flex flex-row align-items-end justify-content-between footer">
                    <div className="text-lg-center continue-btn-2  ">
                      <Link
                        to={"/home"}
                        onClick={() => {
                          dispatch(setShowPaywallModal(false))
                          setRestrictionModal(false)
                        }}
                      >
                        Later
                      </Link>
                    </div>

                    <div className="text-lg-center continue-btn-2">
                      <div
                        text="Continue with free"
                        type="button"
                        onClick={() => handleAction(redirectUrl)}
                      >
                        Premium <span className='fw-normal'> - Unlock All Content</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default UserRestriction

const ListItem=({text})=>( <li className='d-flex'><p className='list-style-unlock-style mb-0'>+</p><p className='mb-0 '>{text}.</p></li>)
